import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pr-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss'],
})
export class HomeFooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  showCCSettings() {
    try {
      // ./assets/js/cookieconsent.js has to be load before
      if (!window.CookieConsent) {
        return;
      }
      window.CookieConsent.showSettings();
    } catch (ex) {
      console.error('Error show cookie settings');
      console.error(ex);
    }
  }
}
