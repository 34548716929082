<ng-container *ngIf="cmsData$ | async as cmsData">
  <!-- <pr-menu-sticky
    [offsetTop]="offsetTop"
    [submenus]="cmsData.sections | prSectionsNames"
    [activeMenu]="activeUrl"
    [activeSubmenu]="scrolledToSection"
  ></pr-menu-sticky>
  <pr-prahapracuje-widget></pr-prahapracuje-widget>
  <pr-cms-page
    [data]="cmsData"
    (scrolledToSection)="scrolledToSection = $event"
  ></pr-cms-page> -->
</ng-container>
