<ng-container *ngIf="state$ | async as state">
  <pr-loading *ngIf="state.loadingVisible" [@fadeInOutLoading]></pr-loading>

</ng-container>

<div class="ban">
  <div class="header">
    <div class="page-container top-header-container smaller-container">
      <div class="header-content">
        <div class="left-side">
          <img
            src="assets/images/logo.svg"
            alt="Logo Pragozor"
            (click)="toHome()"
          />
        </div>
        <div class="right-side">
        </div>
      </div>
    </div>
  </div>
  <pr-parallax #parallax></pr-parallax>
  <div class="page-container header-container">
    <div class="row">
      <div class="col-md-6">
        <div class="card-block">
          <a [href]="PragueInNumbersUrl" target="_blank">
            <div class="header-block">
              <h2 class="div">
                <span class="secondary-color">Praha </span>v číslech
              </h2>
              <img [src]="'assets/images/arrow-left.svg'" />
            </div>
          </a>
          <span class="text">
            Chcete se dozvědět více o Praze? Objevte zajímavá fakta a statistiky
            o Praze pomocí grafů, tabulek a čísel. Prozkoumejte různá témata,
            která se týkají ekonomiky, dopravy a dalších aspektů života v Praze.
          </span>
          <a [href]="PragueInNumbersUrl" target="_blank">
            <img [src]="'assets/images/LogoDataPraha.svg'" />
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card-block">
          <a [href]="prahaPracujeUrl" target="_blank">
            <div class="header-block">
              <h2 class="div">
                Projekty <span class="secondary-color">v Praze</span>
              </h2>
              <img [src]="'assets/images/arrow-left.svg'" />
            </div>
          </a>
          <span class="text">
            Aktuálně probíhá na území Prahy více než 500 klíčových projektů, na
            které dohlížíme. Níže si můžete některé z nich prohlédnout.
          </span>
          <a [href]="prahaPracujeUrl" target="_blank">
            <img [src]="'assets/images/LogoPragozor.svg'" />
          </a>
        </div>
      </div>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
<pr-home-footer class="pr-home-footer"></pr-home-footer>
