import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FullWidthBannerComponent, BaseComponent } from '@shared/components';
import { Observable, combineLatest } from 'rxjs';
import { CmsPageModel } from '@shared/models';
import { CmsService, ScrollService, StateService } from '@core/services';
import { filter, map, delay, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'pr-home-welcome',
  templateUrl: './home-welcome.component.html',
  styleUrls: ['./home-welcome.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeWelcomeComponent extends BaseComponent implements OnInit {
  cmsData$: Observable<CmsPageModel>;
  showErrorMsg$: Observable<boolean>;
  activeUrl: string;
  scrolledToSection = null;
  get offsetTop() {
    return this.el.nativeElement.offsetTop;
  }

  constructor(
    cmsService: CmsService,
    router: Router,
    private el: ElementRef,
    scrollService: ScrollService,
    activatedRoute: ActivatedRoute,
    private state: StateService
  ) {
    super();
    this.cmsData$ = cmsService.getCmsDataForPage('home');
    this.activeUrl = router.url;

    const sectionToScroll$: Observable<string> = combineLatest([
      activatedRoute.queryParams,
      this.cmsData$,
    ]).pipe(
      delay(1000),
      tap(([queryParam, _]) => {
        if (queryParam.section == null) {
          state.setLoadingVisibility(false);
        }
      }),
      filter(([queryParam, cmsData]) => {
        return queryParam.section != null && cmsData != null;
      }),
      map(([queryParam, cmsData]) =>
        cmsData.widgetIds.indexOf(queryParam.section) > -1 ||
        cmsData.sectionUrls.indexOf(queryParam.section) > -1
          ? (queryParam.section as string)
          : null
      )
    );
    this.showErrorMsg$ = sectionToScroll$.pipe(
      map((section) => {
        return section == null;
      })
    );
    sectionToScroll$
      .pipe(
        delay(2000),
        tap((section) => {
          if (section != null) {
            scrollService.scrollToSectionWithoutAnimation(section, -25);
          } else {
            scrollService.scrollToSectionWithoutAnimation('unavailable-widget');
          }
          state.setLoadingVisibility(false);
        }),
        takeUntil(this.baseSubject)
      )
      .subscribe();
  }

  ngOnInit() {}
}
