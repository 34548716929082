<div class="footer">
  <div class="page-container top-header-container">
    <!-- <h4 prScrollReveal>Máme na výběr další kategorie!</h4>
    <pr-home-menu></pr-home-menu> -->
    <div class="copyright">
      <p prScrollReveal>
        Operátor ICT © Pragozor 2023 <br />
        <!-- <a
          href="javascript:void(null);"
          (click)="showCCSettings()"
          >Cookies</a
        > -->
      </p>
    </div>
  </div>
</div>
