import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CommonHttpService, CommonAppConfig } from '@core/services';
import { ContactFormModel } from '../models';

@Injectable()
export class HomeService {
  ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private http: CommonHttpService,
    private config: CommonAppConfig
  ) {}

  submitContact(data: ContactFormModel): Observable<boolean> {
    const body: {
      email: string;
      body: string;
      'g-recaptcha-response': string;
    } = {
      email: data.email,
      body: data.message,
      'g-recaptcha-response': data.recaptcha,
    };
    const url = this.config.get().apiUrl + '/contactform';
    return this.http.post(url, body, (x: any) => {
      return true;
    });
  }
}
