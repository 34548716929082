import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CmsHeaderModel } from '@shared/models/cms/cms-header.model';
import { CmsService, StateService } from '@core/services';
import { BaseComponent } from '@shared/components';
import {
  fadeInOutAnimation,
  fadeInOutLoadingAnimation,
} from '@shared/animations';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonAppConfig } from '@core/services';
import { RecaptchaComponent } from 'ng-recaptcha';
import { HomeService } from '../../services';
import { StateModel } from '@shared/models/common';

@Component({
  selector: 'pr-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAnimation, fadeInOutLoadingAnimation],
})
export class HomeComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  state$: Observable<StateModel>;
  @ViewChild(RecaptchaComponent) recaptcha: RecaptchaComponent;
  header$: Observable<CmsHeaderModel>;
  isContactModalVisible = false;
  showSuccessContactMsg = false;
  showErrorContactMsg = false;
  contactFormLoading = false;
  siteKey: string;
  prahaPracujeUrl: string;
  PragueInNumbersUrl: string;
  contactFormGroup: FormGroup;

  inCategory: string;
  category: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private cmsService: CmsService,
    private homeService: HomeService,
    fb: FormBuilder,
    config: CommonAppConfig,
    private renderer: Renderer2,
    private stateService: StateService
  ) {
    super();
    this.prahaPracujeUrl = config.get().prahaPracujeUrl;
    this.PragueInNumbersUrl = config.get().PragueInNumbersUrl;
    stateService.setLoadingVisibility(true);
    this.siteKey = config.get().recaptchaSiteKey;
    this.contactFormGroup = fb.group({
      email: fb.control(null, [Validators.required, Validators.email]),
      message: fb.control(null, Validators.required),
      agreement: fb.control(false, [Validators.requiredTrue]),
    });
    this.header$ = this.cmsService.getCmsHeader();
    this.state$ = this.stateService.state$;
    this.setMissingSectionTexts(router.url);
  }

  ngOnInit() {}

  ngAfterViewInit(): void {}

  toHome() {
    this.router.navigate(['/']);
  }

  closeMissingSection() {
    this.stateService.setMissingSectionVisibility(false);
    this.renderer.removeClass(document.body, 'hide-scroll');
  }

  recaptchaExecuted(recaptcha: string) {
    this.homeService
      .submitContact({
        ...this.contactFormGroup.value,
        recaptcha,
      })
      .subscribe((response) => {
        if (response) {
          this.contactFormGroup.reset();
          this.showSuccessContactMsg = true;
        } else {
          this.showErrorContactMsg = true;
        }
        this.contactFormLoading = false;
        this.cdr.markForCheck();
      });
  }

  private setMissingSectionTexts(url: string) {
    if (url.indexOf('natural-environment') - 1) {
      this.inCategory = 'životní prostředí';
      this.category = 'životní prostředí';
    } else if (url.indexOf('transport') - 1) {
      this.inCategory = 'MHD';
      this.category = 'MHD';
    } else if (url.indexOf('tourism') - 1) {
      this.inCategory = 'turismu';
      this.category = 'turismus';
    } else if (url.indexOf('housing') - 1) {
      this.inCategory = 'bydlení';
      this.category = 'bydlení';
    } else if (url.indexOf('cars') - 1) {
      this.inCategory = 'aut';
      this.category = 'auta';
    } else if (url.indexOf('mobility') - 1) {
      this.inCategory = 'mobility';
      this.category = 'mobilita';
    } else if (url.indexOf('recycling') - 1) {
      this.inCategory = 'recyklace';
      this.category = 'recyklace';
    } else if (url.indexOf('security') - 1) {
      this.inCategory = 'bezpečnosti';
      this.category = 'bezpečnost';
    } else if (url.indexOf('curiosity') - 1) {
      this.inCategory = 'aktuality';
      this.category = 'aktuality';
    } else if (url.indexOf('economy') - 1) {
      this.inCategory = 'ekonomika';
      this.category = 'ekonomika';
    } else if (url.indexOf('education') - 1) {
      this.inCategory = 'vzdělávání';
      this.category = 'vzdělávání';
    }
  }
}
