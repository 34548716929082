import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'pr-prahapracuje-widget',
  templateUrl: './prahapracuje-widget.component.html',
  styleUrls: ['./prahapracuje-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrahaPracujeWidgetComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
