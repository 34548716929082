import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'pr-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDeleteComponent implements OnInit {
  @Input() inCategory: string;
  @Input() category: string;
  @Output() homeClicked: EventEmitter<void> = new EventEmitter();
  @Output() closeClicked: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
