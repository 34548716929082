import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScullyLibModule } from '@scullyio/ng-lib';

import { HomeRoutingModule } from './home-routing.module';
import * as components from './components';
import * as services from './services';
import { SharedModule } from '../shared/shared.module';
import { ParallaxScrollModule } from 'ng2-parallaxscroll';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

const COMPONENTS = [
  components.HomeComponent,
  components.HomeWelcomeComponent,
  components.HomeFooterComponent,
  components.ParallaxComponent,
  components.ModalDeleteComponent,
  components.PrahaPracujeWidgetComponent,
];

const SERVICES = [services.HomeService];

@NgModule({
  declarations: [...COMPONENTS],
  providers: [...SERVICES],
  imports: [
    SharedModule,
    CommonModule,
    HomeRoutingModule,
    ScullyLibModule,
    ParallaxScrollModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
})
export class HomeModule {}
