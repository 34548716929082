import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent, HomeWelcomeComponent } from './components';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        component: HomeWelcomeComponent,
      },
      {
        path: 'natural-environment',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'transport',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'tourism',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'housing',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'cars',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'health',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'mobility',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'recycling',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'security',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'citymanagement',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'curiosity',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'economy',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'education',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: 'test',
        redirectTo: '/',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
